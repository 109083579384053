<!-- CameraPopup.vue -->
<template>
  <el-dialog
      title="身份核对"
      class="camera-popup" 
      :visible.sync="showPopup"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="50%"
      custom-class="camera-popup-dialog"
  >
    <div class="popup-inner">
      <div class="loading" v-if="loading">
        <div class="spinner"></div>
        <p>身份核对中...</p>
      </div>
    </div>
    <video v-show="!photoTaken" ref="video" autoplay></video>
    <canvas v-show="photoTaken" ref="canvas"></canvas>
    <p v-if="!noCameraFound && !cameraDenied" class="instruction-text">
      该课程学习需要核对您的身份信息，请拍照提交审核
    </p>
    <p v-if="noCameraFound" class="camera-error">
      您的设备似乎没有摄像头。请使用有摄像头的设备进行本课程的学习。
    </p>
    <p v-if="cameraDenied" class="camera-error">
      您已拒绝摄像头权限。请在浏览器设置中允许我们访问您的摄像头，然后刷新页面。
    </p>
   <template #footer>
      <div v-if="!noCameraFound && !cameraDenied">
        <el-button
            v-if="photoTaken"
            type="warning"
            @click="retake"
            icon="el-icon-refresh"
        >
          重新拍照
        </el-button>
        <el-button v-else type="primary" @click="takePhoto" icon="el-icon-camera">
          拍照
        </el-button>
        <el-button
            v-if="photoTaken"
            type="success"
            @click="submit"
            icon="el-icon-check"
        >
          提交识别
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    showPopup: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      // loading: false,
      stream: null,
      photoTaken: false,
      cameraDenied: false,
      noCameraFound: false,
    };
  },
  mounted() {
    if (this.showPopup) {
      this.startCamera();
    }
  },
  watch: {
    showPopup(newVal) {
      if (newVal) {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    },
  },
  methods: {
    async startCamera() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
            (device) => device.kind === "videoinput"
        );

        if (videoDevices.length === 0) {
          this.noCameraFound = true;
          return;
        }
        this.stream = await navigator.mediaDevices.getUserMedia({video: true});
        this.$refs.video.srcObject = this.stream;
      } catch (err) {
        console.error("无法访问摄像头：", err);
        if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
          this.cameraDenied = true;
        }
      }
    },
    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
        this.stream = null;
      }
    },
    takePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0);
      this.photoTaken = true;
    },
    retake() {
      this.photoTaken = false;
    },
    submit() {
      const canvas = this.$refs.canvas;
      const imageData = canvas.toDataURL("image/jpeg");
      this.stopCamera();
      this.photoTaken = false;
      this.loading = true;
      // 在这里处理提交识别的逻辑
      this.$emit("image-blob", this.transferImage2Blob(imageData));
    },

    transferImage2Blob(imageData) {
      // 将dataURL转换为Blob
      const byteString = atob(imageData.split(",")[1]);
      const mimeString = imageData.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], {type: mimeString});
      // 将Blob转换为File对象
      // const file = new File([blob], "your-image-name.jpg", { type: mimeString });
    }
  },
};
</script>

<style scoped>
.camera-popup-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

canvas {
  border: 1px solid #ddd;
}

.camera-popup-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

canvas {
  border: 1px solid #ddd;
}

.instruction-text {
  margin-bottom: 16px;
}

.camera-error {
  color: red;
  margin-bottom: 16px;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>
