<template>
  <div class="task">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="fromSocure">返回课程</el-breadcrumb-item>
      <el-breadcrumb-item>{{ testName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="cont">
      <!-- 考试结果 汇总 -->
      <el-row :gutter="20" v-if="showTaskResult && showResultDetail">
        <el-col :span="24">
          <el-card shadow="nerve" class="card">
            <div class="task-result">
<!--              <div class="icon-box">-->
<!--                &lt;!&ndash; <i class="el-icon-collection-tag"></i> &ndash;&gt;-->
<!--                <img v-if="isPass" class="img"-->
<!--                  src="https://www.wlmqcol.com/static-dist/app/img/item-bank/passed@2x.png?version=8.8.0" />-->
<!--                <img v-else class="img"-->
<!--                  src="https://www.wlmqcol.com/static-dist/app/img/item-bank/unpassed@2x.png?version=8.8.0" />-->
<!--              </div>-->
              <div class="content">
                <p v-if="isPass" class="tit">本次考试得分{{ score.toFixed(1) }}。恭喜，您通过本次考试！</p>
                <p v-else class="tit">本次考试得分{{ score.toFixed(1) }}。很遗憾，您未通过本次考试！<span class="retestbtn" v-if="canRetry"
                    @click="retestfun">重新考试</span></p>
                <!-- <p class="desc">教师评语 ：-</p> -->
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="showTaskResult && !showResultDetail">
        <div style="height:95vh">
          <el-col :span="24">
            <el-card shadow="nerve" class="card">
              <div class="task-result">
<!--                <div class="icon-box">-->
<!--                  &lt;!&ndash; <i class="el-icon-collection-tag"></i> &ndash;&gt;-->
<!--                  <img v-if="isPass" class="img"-->
<!--                    src="https://www.wlmqcol.com/static-dist/app/img/item-bank/passed@2x.png?version=8.8.0" />-->
<!--                  <img v-else class="img"-->
<!--                    src="https://www.wlmqcol.com/static-dist/app/img/item-bank/unpassed@2x.png?version=8.8.0" />-->
<!--                </div>-->
                <div class="content">
                  <p v-if="isPass" class="tit">本次考试得分{{ score.toFixed(1) }}。恭喜，您通过本次考试！</p>
                  <p v-else class="tit">本次考试得分{{ score.toFixed(1) }}。很遗憾，您未通过本次考试！<span class="retestbtn" v-if="canRetry"
                      @click="retestfun">重新考试</span></p>
                  <!-- <p class="desc">教师评语 ：-</p> -->
                </div>
              </div>
            </el-card>
          </el-col>
        </div>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="18">
          <el-card shadow="nerve" class="card" v-if="showTaskResult && showResultDetail">
            <div v-if="!showTaskResult" class="header">
              <h3>{{ testName }}</h3>
              <span style="margin-top: 0px;">答题中</span>
            </div>
            <!-- 考试结果成绩表格 -->
            <div v-if="showResultDetail" class="result-table">
              <div class="result-header">
                <div class="left-box">
                  <div class="header">
                    <h3>{{ testName }}</h3>
                    <span style="margin-top: 0px;">批阅完成</span>
                  </div>
                  <div class="content">
                    答题人：{{ userName }}&nbsp;&nbsp;&nbsp;&nbsp;交卷时间：{{
                      submitTime
                    }}
                    <div style="margin-top: 10px;margin-bottom: 10px;">用时：{{ timeCost }}</div>
                  </div>

                </div>
                <div class="right-box">
                  <div class="cont">
                    <p class="defen">{{ score.toFixed(1) }}</p>
                    <p class="zhongfen">总分：{{ totalScore }}</p>
                  </div>
                </div>
              </div>
              <div v-if="showResultDetail">
                <p class="tit">单选题汇总：</p>
                <el-table :data="singleTableData" border style="width: 100%"
                  :header-cell-style="{ background: '#f5f5f5' }">
                  <el-table-column prop="rightNum" label="正确数量">
                  </el-table-column>
                  <el-table-column prop="wrongNum" label="错误数量">
                  </el-table-column>
                  <el-table-column prop="blankNum" label="空白数量">
                  </el-table-column>
                  <el-table-column prop="questionNum" label="总数"></el-table-column>
                  <el-table-column prop="singleScore" label="分值">
                  </el-table-column>
                </el-table>
                <p class="tit">多选题汇总：</p>
                <el-table :data="multipleTableData" border style="width: 100%"
                  :header-cell-style="{ background: '#f5f5f5' }">
                  <el-table-column prop="rightNum" label="正确数量">
                  </el-table-column>
                  <el-table-column prop="wrongNum" label="错误数量">
                  </el-table-column>
                  <el-table-column prop="blankNum" label="空白数量">
                  </el-table-column>
                  <el-table-column prop="questionNum" label="总数"></el-table-column>
                  <el-table-column prop="singleScore" label="分值">
                  </el-table-column>
                </el-table>
                <p class="tit">判断题汇总：</p>
                <el-table :data="judgmentTableData" border style="width: 100%"
                  :header-cell-style="{ background: '#f5f5f5' }">
                  <el-table-column prop="rightNum" label="正确数量">
                  </el-table-column>
                  <el-table-column prop="wrongNum" label="错误数量">
                  </el-table-column>
                  <el-table-column prop="blankNum" label="空白数量">
                  </el-table-column>
                  <el-table-column prop="questionNum" label="总数"></el-table-column>
                  <el-table-column prop="singleScore" label="分值">
                  </el-table-column>
                </el-table>
                <div v-if="essayTableData.length > 0">
                  <p class="tit">简答题汇总：</p>
                  <el-table :data="essayTableData" border style="width: 100%"
                    :header-cell-style="{ background: '#f5f5f5' }">
                    <el-table-column prop="rightNum" label="正确数量">
                    </el-table-column>
                    <el-table-column prop="wrongNum" label="错误数量">
                    </el-table-column>
                    <el-table-column prop="blankNum" label="空白数量">
                    </el-table-column>
                    <el-table-column prop="questionNum" label="总数"></el-table-column>
                    <el-table-column prop="singleScore" label="分值">
                    </el-table-column>
                  </el-table>
                </div>

              </div>

            </div>
          </el-card>
          <div v-if="!showTaskResult || showResultDetail">
            <el-card v-if="singleChoiceQuestionsArr && singleChoiceQuestionsArr.length > 0" shadow="nerve" class="card">
              <div class="item">
                <div class="tit">单选题</div>
                <span class="desc">共{{ singleCount }}题，共{{ singleScore }}分</span>
              </div>
              <div class="cont">
                <div v-for="(item, index) in singleChoiceQuestionsArr" :key="item.no">
                  <el-row class="shiti-item">
                    <el-col :span="2" class="shiti-left">
                      <div class="timu-no">{{ item.no }}、</div>
                      <div class="fen-num">
                        {{ item.questionPoint.toFixed(1) }} 分
                      </div>
                      <div :class="item.isSign === true ? 'hoverBiaoji' : ''" class="biaoji"
                        @click="handleSign('singleChoice', index)">
                        <i class="el-icon-collection-tag"></i>
                        <span>{{ item.isSign === true ? '取消' : '标记' }}</span>
                      </div>
                    </el-col>
                    <el-col :span="22">
                      <div class="timu-title">{{ item.questionTitle }}</div>
                      <div class="content">
                        <ul>
                          <!-- , index2 -->
                          <li v-for="(item2) in item.optionList" :key="item2.optionCode">
                            <el-radio-group v-model="singleChoiceQuestions[index]" :value="item2.optionCode" @change="changeInput(
                              'singleChoice',
                              index,
                              item2.optionNo,
                              item2.optionCode
                            )
                              ">
                              <el-radio :label="item2.optionNo" :disabled="item.disabled" v-model="item.optionNo">{{
                                item2.optionNo + '、' + item2.optionContent
                              }}</el-radio>
                              <!-- + '-' + index2 -->
                            </el-radio-group>
                          </li>
                          <li v-if="showTaskResult" class="reuslt-li">
                            正确答案：<span>{{ item.trueAnswer ? item.trueAnswer.toUpperCase() : ''
                            }}</span>&nbsp;&nbsp;&nbsp;&nbsp;你的答案：<span v-if="item.submitAnswer">{{ item.submitAnswer ?
  item.submitAnswer.toUpperCase() : ''
}}</span><span style="color:#999" v-else>
                              未作答
                            </span>
                          </li>
                          <li v-if="showTaskResult&&item.analysis" class="reuslt-li">[答案解释]:  {{ item.analysis }}</li>
                        </ul>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-card>
            <el-card v-if="multipleChoiceQuestionsArr && multipleChoiceQuestionsArr.length > 0" shadow="nerve"
              class="card">
              <div class="item">
                <div class="tit">多选题</div>
                <span class="desc">共{{ multipleCount }}题，共{{ multipleScore }}分</span>
              </div>
              <div class="cont">
                <div v-for="(item, index) in multipleChoiceQuestionsArr" :key="item.no">
                  <el-row class="shiti-item">
                    <el-col :span="2" class="shiti-left">
                      <div class="timu-no">{{ item.no }}、</div>
                      <div class="fen-num">
                        {{ item.questionPoint.toFixed(1) }} 分
                      </div>
                      <div :class="item.isSign === true ? 'hoverBiaoji' : ''" class="biaoji"
                        @click="handleSign('multipleChoice', index)">
                        <i class="el-icon-collection-tag"></i>
                        <span>{{ item.isSign === true ? '取消' : '标记' }}</span>
                      </div>
                    </el-col>
                    <el-col :span="22">
                      <div class="timu-title">{{ item.questionTitle }}</div>
                      <div class="content">
                        <ul>
                          <li v-for="(item2, index2) in item.optionList" :key="item2.optionCode">
                            <el-checkbox-group v-model="multipleChoiceQuestions[index]" :value="item2.optionCode" @change="changeInput(
                              'multipleChoice',
                              index,
                              item2.optionNo,
                              item2.optionCode
                            )
                              ">
                              <el-checkbox :label="item2.optionNo" :disabled="item.disabled">
                                {{
                                  item2.optionNo.toUpperCase() + '、' + item2.optionContent
                                }}
                              </el-checkbox>
                              <!-- + '-' + index2 + '-' + index -->
                              <span>{{ multipleChoiceQuestions[index2] }}-c</span>
                            </el-checkbox-group>
                          </li>
                          <li v-if="showTaskResult" class="reuslt-li">
                            正确答案：<span>{{ item.trueAnswer ? item.trueAnswer.toUpperCase() : ''
                            }}</span>&nbsp;&nbsp;&nbsp;&nbsp;你的答案：<span v-if="item.submitAnswer">{{ item.submitAnswer ?
  item.submitAnswer.toUpperCase() : ''
}}</span><span style="color:#999" v-else>
                              未作答
                            </span>
                          </li>
                          <li v-if="showTaskResult&&item.analysis" class="reuslt-li">[答案解释]:  {{ item.analysis }}</li>
                        </ul>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-card>
            <el-card v-if="judgmentQuestionsArr && judgmentQuestionsArr.length > 0" shadow="nerve" class="card">
              <div class="item">
                <div class="tit">判断题</div>
                <span class="desc">共{{ judgeCount }}题，共{{ judgeScore }}分</span>
              </div>
              <div class="cont">
                <div v-for="(item, index) in judgmentQuestionsArr" :key="item.no">
                  <el-row class="shiti-item">
                    <el-col :span="2" class="shiti-left">
                      <div class="timu-no">{{ item.no }}、</div>
                      <div class="fen-num">
                        {{ item.questionPoint.toFixed(1) }} 分
                      </div>
                      <div :class="item.isSign === true ? 'hoverBiaoji' : ''" class="biaoji"
                        @click="handleSign('judgment', index)">
                        <i class="el-icon-collection-tag"></i>
                        <span>{{ item.isSign === true ? '取消' : '标记' }}</span>
                      </div>
                    </el-col>
                    <el-col :span="22">
                      <div class="timu-title">{{ item.questionTitle }}</div>
                      <div class="content">
                        <ul>
                          <!-- , index3 -->
                          <li v-for="(item3) in item.optionList" :key="item3.optionCode">
                            <el-radio-group v-model="judgmentQuestions[index]" :value="item3.optionCode" @change="changeInput(
                              'judgment',
                              index,
                              item3.optionNo,
                              item3.optionCode
                            )
                              ">
                              <el-radio :label="item3.optionNo" :disabled="item.disabled">{{
                                item3.optionContent
                              }}</el-radio>
                              <!-- + '-' + index3 -->
                            </el-radio-group>
                          </li>
                          <li v-if="showTaskResult" class="reuslt-li">
                            正确答案：<span>{{ item.trueAnswer == 'F' ? '错误' : '正确' }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            你的答案：<span v-if="item.submitAnswer.length>0">{{ item.submitAnswer == 'F' ? '错误' : '正确' }}</span>
                            <span style="color:#999" v-else>
                              未作答
                            </span>
                          </li>
                          <li v-if="showTaskResult&&item.analysis" class="reuslt-li">[答案解释]:  {{ item.analysis }}</li>
                        </ul>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-card>
            <el-card v-if="essayQuestionsArr && essayQuestionsArr.length > 0" shadow="nerve" class="card">
              <div class="item">
                <div class="tit">问答题</div>
                <span class="desc">共{{ essayCount }}题，共{{ essayScore }}分</span>
              </div>
              <div class="cont">
                <div v-for="(item, index) in essayQuestionsArr" :key="item.no">
                  <el-row class="shiti-item">
                    <el-col :span="2" class="shiti-left">
                      <div class="timu-no">{{ item.no }}、</div>
                      <div class="fen-num">
                        {{ item.questionPoint.toFixed(1) }} 分
                      </div>
                      <div :class="item.isSign === true ? 'hoverBiaoji' : ''" class="biaoji"
                        @click="handleSign('essay', index)">
                        <i class="el-icon-collection-tag"></i>
                        <span>{{ item.isSign === true ? '取消' : '标记' }}</span>
                      </div>
                    </el-col>
                    <el-col :span="22">
                      <div class="timu-title">{{ item.questionTitle }}</div>
                      <div class="content">
                        <ul>
                          <!-- , index3 -->
                          <li v-if="!showTaskResult">
                            <el-input type="textarea" placeholder="请输入内容" v-model="item.answerCode">
                            </el-input>
                          </li>
                          <li v-if="showTaskResult" class="reuslt-li">
                            正确答案：<span>{{ item.trueAnswer }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <br />
                            你的答案：<span v-if="item.submitAnswer">{{ item.submitAnswer }} </span>
                            <span style="color:#999" v-else>
                              未作答
                            </span>
                          </li>
                          <li v-if="showTaskResult&&item.analysis" class="reuslt-li">[答案解释]:  {{ item.analysis }}</li>
                        </ul>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="6" :style="{ 'opacity': getTestData ? 1 : 0 }" v-if="(!showTaskResult || showResultDetail)">
          <el-card shadow="nerve" class="card" v-if="!showTaskResult">
            <div class="item border-none">
              <Countdown ref="countdown" @showResult="showResult" @autoSave="handleSave" :intervalTime='intervalTime'
                :needMonitor='needMonitor' @intervalTimeMonitorFun="intervalTimeMonitorFun" />
              <!-- <div class="flex">
                <p class="time">倒计时：{{remainTime}}</p>
                <div class="r-wrap">
                  <i class="el-icon-video-pause"></i>
                </div>
              </div> -->
            </div>
          </el-card>
          <div class="r-item-wrap">
            <el-card shadow="nerve" :class="fixed === true ? 'fixed card' : 'card'">
              <div class="item">
                <div class="tit">答题卡</div>
                <div @click="changeAnswer" class="desc floatR">
                  {{ isVisible === false ? '展开' : '收起' }}答题卡
                </div>
              </div>
              <div v-if="!showTaskResult" class="btm-wrap">
                <el-button @click="handleSave" type="success" size="small" round>保存进度</el-button>
                <el-button @click="handleSubmitCheckNeedMonitor2" type="primary" size="small" round>立即提交</el-button>
              </div>
              <div v-else class="btm-wrap">
                <el-button v-if="canRetry" @click="retestfun" type="primary" size="small" round>再次答卷</el-button>
              </div>
              <div ref="answerDiv" class="cont answer">
                <div v-if="singleChoiceQuestionsArr && singleChoiceQuestionsArr.length > 0">
                  <div class="cate-tit">单选题</div>
                  <ul class="answer-ul">
                    <li v-for="(item, index) in singleChoiceQuestionsArr" :key="item.no"
                      :class="[item.isChoose === true ? 'click' : '', showTaskResult && !item.ifRight ? 'error' : '']">
                      <i :class="item.isSign === true ? 'el-icon-collection-tag' : ''
                        "></i>
                      <span>{{ index + 1 }}</span>
                    </li>
                  </ul>
                </div>
                <div v-if="multipleChoiceQuestionsArr && multipleChoiceQuestionsArr.length > 0">
                  <div class="cate-tit">多选题</div>
                  <ul class="answer-ul">
                    <li v-for="(item, index) in multipleChoiceQuestionsArr" :key="item.no"
                      :class="[item.isChoose === true ? 'click' : '', showTaskResult && !item.ifRight ? 'error' : '']">
                      <i :class="item.isSign === true ? 'el-icon-collection-tag' : ''
                        "></i>
                      <span>{{ index + 1 }}</span>
                    </li>
                  </ul>
                </div>
                <div v-if="judgmentQuestionsArr && judgmentQuestionsArr.length > 0">
                  <div class="cate-tit">判断题</div>
                  <ul class="answer-ul">
                    <li v-for="(item, index) in judgmentQuestionsArr" :key="item.no"
                      :class="[item.isChoose === true ? 'click' : '', showTaskResult && !item.ifRight ? 'error' : '']">
                      <i :class="item.isSign === true ? 'el-icon-collection-tag' : ''
                        "></i>
                      <span>{{ index + 1 }}</span>
                    </li>
                  </ul>
                </div>
                <div v-if="essayQuestionsArr && essayQuestionsArr.length > 0">
                  <div class="cate-tit">简答题</div>
                  <ul class="answer-ul">
                    <li v-for="(item, index) in essayQuestionsArr" :key="item.no"
                      :class="[item.answerCode && item.answerCode.length > 0 ? 'click' : '', showTaskResult && !item.ifRight? 'error' : '']">
                      <i :class="item.isSign === true ? 'el-icon-collection-tag' : ''
                        "></i>
                      <span>{{ index + 1 }}</span>
                    </li>
                  </ul>
                </div>

              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <camera-popup :showPopup="showCameraPopup" :loading="false" @image-blob="onImageCaptured"></camera-popup>
  </div>
</template> 

<script>
import {
  getPaper,
  getTest,
  reTest,
  // getPrepare,
  getCheckTime,
  // checkLastTest,
  result,
  save,
  submit,
  getTaskResultData
} from '@/api/task'
import { faceCompare } from '@/api/course'
import { mapGetters } from 'vuex'
import { formatDuring } from '@/utils/index'
import Countdown from '@/components/Countdown'
import CameraPopup from '@/components/Monitor/index.vue'
import {uploadPrivateIdentifyImage} from "@/utils/upload";
export default {
  name: 'Task',
  components: {
    Countdown,
    CameraPopup
  },
  data () {
    return {
      tcPlayerId: 'tcPlayer' + Date.now(),
      showCameraPopup: false,
      fixed: false, // 答题栏fixed标记
      isVisible: true, // 展示答题卡标记
      remainTime: '', //倒计时文案
      singleChoiceQuestionsArr: [], //单选题数据源
      singleChoiceQuestions: [], //

      essayQuestionsArr: [], //简答
      essayQuestions: [], //

      multipleChoiceQuestionsArr: [], //多选题数据源
      multipleChoiceQuestions: [], //

      judgmentQuestionsArr: [], //判断题
      judgmentQuestions: [], // 判断题
      periodId: null, //课时id
      courseId: null, //课程id
      prepareData: {}, // 考试说明数据
      testName: '', //  试题名称
      singleTableData: [], // 点选题考试结果
      multipleTableData: [], // 多选题考试结果
      judgmentTableData: [], // 判断题考试结果
      essayTableData: [], // 判断题考试结果
      showTaskResult: false, // 是否显示考试结果
      isFinished: false, //最近一次考试是否结束true已结束 false未结束
      isSubmitted: false, //最近一次考试是否提交true已提交 false未提交
      examId: null, //最近一次考试id
      fromSocure: '', // 上一页路径
      answerArr: {
        //答案数据
        examId: '', //试题id
        answerList: [] //答案提交内容
      },
      timeCost: null, // 耗时 秒
      submitTime: null, // 交卷时间
      userName: null, // 答题人名称
      score: 0, //得分，
      totalScore: 0, // 总分

      singleScore: 0, // 单选题总分
      singleCount: 0, // 单选题数

      multipleScore: 0, // 多选题总分
      multipleCount: 0, // 多选题题数

      judgeScore: 0, // 判断题总分
      judgeCount: 0, // 判断题题数

      essayScore: 0, // 判断题总分
      essayCount: 0, // 判断题题数

      isPass: null, // 是否通过考试
      showResultDetail: false, //是否展示考试结果数据
      needEndMonitor: 1,
      needMonitor: 1,
      needStartMonitor: 1,
      intervalTime: 0,
      isSubmit: false,//是否提交试卷，用于拍照校验
      canRetry: false,
      correctFinished: false,
      showResultnow: false,
      getTestData: false,
      examType: 0,
      isSubmit3: false,
      isbeforeDestroy:false
    }
  },

  // 获取上一页来源路径
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.fromSocure = from.fullPath
    })
  },
  beforeDestroy () {
    this.isbeforeDestroy = true;
    console.log('beforeDestroybeforeDestroybeforeDestroybeforeDestroy')
    this.$refs.countdown.clearCutDown();
  },
  computed: {
    ...mapGetters(['userId'])
  },
  async mounted () {
    window.addEventListener('scroll', this.fixedCard)
    this.periodId = this.$route.query.periodId
    this.courseId = this.$route.query.courseId
    // if (this.$route.query.isFirst) {
    //   // 查询最近一次考试状态
    //  // await this.searchTestStatus()
    //   //首次考试
    //   //await this.firstStudy();
    //   await this.getPaper();
    // }
    this.getPaper()
  },
  methods: {
    intervalTimeMonitorFun () {
      this.showCameraPopup = true;
    },
    //
    identifyImgName () {
      //date.new() + 随机数
      return Date.now() + Math.floor(Math.random() * 10000) + '.jpeg';
    },
    onImageCaptured (imageBlob) {
      // 将dataURL转换为Blob
      // 将Blob转换为File对象
      const file = new File([imageBlob], this.identifyImgName(), { type: "image/jpeg" });
      uploadPrivateIdentifyImage(file, (url) => {
        let params = {
          periodId: this.periodId,
          courseId: this.courseId,
          async: this.examType != 3 ? false : true,
          imageUrl: url,
          freqFlag: 0
        }
        // this.examType!=3 非正式考试不需要等到校验成功
        if (this.examType == 3) {
          this.showCameraPopup = false
          if (this.isSubmit) {
            this.$refs.countdown.clearCutDown()
            if (this.isSubmit3) {
              this.handleSubmit3()
            } else {
              this.handleSubmit()
            }
          }
        }
        faceCompare(params).then(res => {
          if (res.status === 200) {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '验证成功',
                duration: 1000,
              })
              this.showCameraPopup = false
              if (this.examType != 3) {
                if (this.isSubmit) {
                  this.$refs.countdown.clearCutDown()
                  if (this.isSubmit3) {
                    this.handleSubmit3()
                  } else {
                    this.handleSubmit()
                  }
                }
              }
            } else {
              if (this.examType != 3) {
                this.$message({
                  type: 'error',
                  message: res.data.message,
                  duration: 1000,
                })
                this.showCameraPopup = false
                setTimeout(() => {
                  this.showCameraPopup = true
                }, 2000)
              }

            }
          }
        })

      });
    },
    fixedCard () {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      scrollTop >= 120 ? (this.fixed = true) : (this.fixed = false)
    },
    //重新考试
    retestfun () {
      const obj = {
        // userId: this.userId,
        courseId: this.courseId, //课程id
        periodId: this.periodId //课时章节id
      }
      reTest(obj).then((res) => {

        this.getTestData = true;
        // setTimeout(()=>{
        //   location.reload()
        // },2000)
        this.examId = res.data.data.examId
        this.testName = res.data.data.testName
        this.singleChoiceQuestionsArr = res.data.data.paperData.singleChoiceQuestions
        this.multipleChoiceQuestionsArr = res.data.data.paperData.multipleChoiceQuestions
        this.judgmentQuestionsArr = res.data.data.paperData.judgmentQuestions
        this.essayQuestionsArr = res.data.data.paperData.essayQuestions

        // 给各类型的分数、题数
        this.singleScore = res.data.data.summary.singleScore
        this.singleCount = res.data.data.summary.singleCount
        this.multipleScore = res.data.data.summary.multipleScore
        this.multipleCount = res.data.data.summary.multipleCount
        this.judgeScore = res.data.data.summary.judgeScore
        this.judgeCount = res.data.data.summary.judgeCount
        this.essayCount = res.data.data.summary.essayCount
        this.essayScore = res.data.data.summary.essayScore



        this.handleReset()
      }).catch(() => {

      })
    },
    //获取试题
    getTestFun () {
      this.isbeforeDestroy = false;
      const obj = {
        // userId: this.userId,
        courseId: this.courseId, //课程id
        periodId: this.periodId //课时章节id
      }
      getTest(obj).then((res) => {
        this.testName = res.data.data.testName
        this.singleChoiceQuestionsArr = res.data.data.paperData.singleChoiceQuestions
        this.multipleChoiceQuestionsArr = res.data.data.paperData.multipleChoiceQuestions
        // console.log("***this.multipleChoiceQuestionsArr****",this.multipleChoiceQuestionsArr)
        this.judgmentQuestionsArr = res.data.data.paperData.judgmentQuestions
        this.essayQuestionsArr = res.data.data.paperData.essayQuestions

        this.getTestData = true;
        //倒计时
        this.examId = res.data.data.examId
        console.log('倒计时')
        if (this.prepareData.limitTime && this.prepareData.limitTime > 0) {
          this.$refs.countdown.getServiceTime(res.data.data.examId) // 倒计时开始
        }

        //处理单选题
        if (null != this.singleChoiceQuestionsArr && this.singleChoiceQuestionsArr.length > 0) {
          this.singleChoiceQuestionsArr.forEach((item) => {
            Object.assign(item, {
              isChoose: false, // 是否已选择
              isSign: false, // 是否已标记
              optionNo: '', //选项序号
              optionCode: '', //选项编号
              disabled: false
            })
          })
        }
        //处理多选题
        if (null != this.multipleChoiceQuestionsArr && this.multipleChoiceQuestionsArr.length > 0) {
          this.multipleChoiceQuestionsArr.forEach((item) => {
            Object.assign(item, {
              isChoose: false, // 是否已选择
              isSign: false, // 是否已标记
              optionNo: '', //选项序号
              optionCode: '', //选项编号
              disabled: false
            })
            this.multipleChoiceQuestions.push([]) // 绑定相应的el-checkbox-group  的v-model
          })
        }
        //处理判断题
        if (null != this.judgmentQuestionsArr && this.judgmentQuestionsArr.length > 0) {
          this.judgmentQuestionsArr.forEach((item) => {
            Object.assign(item, {
              isChoose: false, // 是否已选择
              isSign: false, // 是否已标记
              optionNo: '', //选项序号
              optionCode: '', //选项编号
              disabled: false
            })
          })
        }

        //处理简答
        console.log(this.essayQuestionsArr, 'this.essayQuestionsArr')
        // if (this.essayQuestionsArr && this.essayQuestionsArr.length > 0) {
        //   this.essayQuestionsArr.forEach((item) => {
        //     Object.assign(item, {
        //       isChoose: false, // 是否已选择
        //       isSign: false, // 是否已标记
        //       optionNo: '', //选项序号
        //       optionCode: '', //选项编号
        //       disabled: false,
        //       answerCode: ''
        //     })
        //   })
        // }
        // 给各类型的分数、题数
        this.singleScore = res.data.data.summary.singleScore
        this.singleCount = res.data.data.summary.singleCount
        this.multipleScore = res.data.data.summary.multipleScore
        this.multipleCount = res.data.data.summary.multipleCount
        this.judgeScore = res.data.data.summary.judgeScore
        this.judgeCount = res.data.data.summary.judgeCount
        this.essayCount = res.data.data.summary.essayCount
        this.essayScore = res.data.data.summary.essayScore

        //监控
        if (this.needMonitor == 0 && this.needStartMonitor == 0) {

          this.showCameraPopup = true
        }

      }).catch(() => {

      })
    },
    //获取考试信息
    getPaper (isFirst) {
      this.isbeforeDestroy = false;
      const obj = {
        // userId: this.userId,
        courseId: this.courseId, //课程id
        periodId: this.periodId //课时章节id
      }
      // let InterfaceUrl = isFirst ? reTest : getPaper //getPaper首次； reRwar 重考
      getPaper(obj).then((res) => {

        if (res.data.code != 0) {
          this.$message({
            type: 'error',
            message: res.data.message
          })
          this.$router.go(-1) // 成功回跳上一页
          return false
        }
        const prepareData = res.data.data
        this.testName = res.data.data.testName
        this.examId = res.data.data.examId
        this.examType = res.data.data.examType;
        this.prepareData = res.data.data;
        this.showResultDetail = res.data.data.showResultDetail;

        this.needEndMonitor = res.data.data.needEndMonitor;
        this.needMonitor = res.data.data.needMonitor;
        this.needStartMonitor = res.data.data.needStartMonitor;
        this.intervalTime = res.data.data.intervalTime;
        this.correctFinished = res.data.data.correctFinished;

        this.showResultnow = res.data.data.showResult;
        if (isFirst) {
          this.firstStudy(true)
          return;
        }
        if (prepareData.canTest) {

          //可以进行考试
          if (prepareData.isContinue) {
            if (prepareData.lastExamFinished) {
              //为true时代表用户上次考试已结束(超时)
              this.lastExamFinishedFun();
            } else {
              //lastExamFinished字段为false时，代表上一次考试未考完，调用获取未完成考试信息接口继续进行考试
              this.continueExam()
            }
          } else {
            //第一次考试直接进入  
            this.firstStudy(true)
            return;
          }

        } else {
          //不可进行考试
          // this.$message({
          //   type: 'error',
          //   message: res.data.data.noticeMsg
          // })
          if (prepareData.hasResult) {
            //hasResult=true时代表用户可以查看上一次考试结果
            this.$confirm(
              `答题已结束，可查看考试结果`,
              `答题已结束`,

              {
                confirmButtonText: '查看考试结果',
                showCancelButton: false,
                showClose: false,
                closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
                closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
              }
            )
              .then(() => {
                this.getTestResult()
              })
              .catch(() => {

              })
          } else {
            //若考生考过但不能看考试结果分析，只显示考试成绩和重新考试的按钮，点击重新考试又可以考试
            // this.showTaskResult = true;
            // this.showResultDetail = false;
            this.$confirm(
              res.data.data.noticeMsg,
              `答题已结束`,
              {
                confirmButtonText: '返回上一页',
                showCancelButton: false,
                type: 'error',
                showClose: false,
                closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
                closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
              }
            )
              .then(() => {
                this.$router.go(-1) // 成功回跳上一页
              })
              .catch(() => {

              })
          }


        }

      })
    },
    //提示考试结束
    lastExamFinishedFun () {
      this.$confirm(
        `答题已结束`,
        '考试结束',
        {
          confirmButtonText: '提交考试结果',
          showCancelButton: false,
          type: 'warning',
          showClose: false,
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
        }
      )
        .then(() => {

          this.handleSubmitCheckNeedMonitor() // 获取考试结果
          /* this.$message({
          type: 'success',
          message: '删除成功!',
        }); */
        })
        .catch(() => {

        })
    },
    //获取接口倒计时
    getCheckTimeFun () {
      getCheckTime({ examId: this.courseId }).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.getCountDown(1675004021755)
        }
      })
    },
    //倒计时
    getCountDown (time) {
      console.log(time)
    },
    //收起/ 展开
    changeAnswer () {
      if (this.isVisible) {
        this.$refs.answerDiv.style.display = 'none'
      } else {
        this.$refs.answerDiv.style.display = 'block'
      }
      this.isVisible = !this.isVisible
    },


    // 处理数据
    handleData () {
      return new Promise((reslove) => {
        this.answerArr.answerList = [] // 先重置数据
        let singAnswerObj = {
          answerNo: '', // 答案序号 =>  optionNo eg: a\b\c\d
          code: '', // 问题编号 => code
          answerCode: '' // 答案编号 => optionCode
        }
        this.answerArr.examId = this.examId
        // 处理单选题数据
        this.singleChoiceQuestionsArr.forEach((item) => {
          singAnswerObj = {
            answerNo: item.optionNo ? item.optionNo : '', // 答案序号 =>  optionNo eg: a\b\c\d
            code: item.code ? item.code : '', // 问题编号 => code
            answerCode: item.optionCode ? item.optionCode : '' // 答案编号 => optionCode
          }
          this.answerArr.answerList.push(singAnswerObj)
        })

        console.log('单选提结果:', this.answerArr)

        // 处理多选题数据
        this.multipleChoiceQuestionsArr.forEach((item, index) => {
          singAnswerObj = {
            answerNo: this.multipleChoiceQuestions[index] ? this.multipleChoiceQuestions[index].toString() : '', // 答案编号 => optionCode, 
            code: item.code, // 问题编号 => code
            answerCode: item.optionNo ? item.optionNo : '' // 答案序号 =>  optionNo eg: a\b\c\d
          }

          this.answerArr.answerList.push(singAnswerObj)
        })
        /* console.log('multipleChoiceQuestions 长度', this.multipleChoiceQuestions.length)
        console.log(this.multipleChoiceQuestions) */
        console.log('多选提结果:', this.answerArr)

        // 处理判断题数据
        this.judgmentQuestionsArr.forEach((item) => {
          singAnswerObj = {
            answerNo: item.optionNo ? item.optionNo : "", // 答案序号 =>  optionNo eg: a\b\c\d
            code: item.code, // 问题编号 => code
            answerCode: item.optionCode||item.optionCode==0?item.optionCode:'' // 答案编号 => optionCode
          }
          this.answerArr.answerList.push(singAnswerObj)
        })

        if (this.essayQuestionsArr && this.essayQuestionsArr.length > 0) {
          this.essayQuestionsArr.forEach((item) => {
            singAnswerObj = {
              answerNo: item.optionNo ? item.optionNo : "", // 答案序号 =>  optionNo eg: a\b\c\d
              code: item.code, // 问题编号 => code
              answerCode: item.answerCode
            }
            this.answerArr.answerList.push(singAnswerObj)
          })
        }

        console.log('判断题结果:', this.answerArr)



        reslove()
      })

    },
    //保存进度
    async handleSave () {
      console.log('handleSave....');
      await this.handleData()
      console.log(this.answerArr, 'this.answerArr')
      console.log(save)
      save(this.answerArr).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '答案保存成功！',
            /* onClose: () => {
              this.$router.go(-1) // 成功回跳上一页
            } */
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 立即提交
    handleSubmitCheckNeedMonitor () {
      if (this.needMonitor == 0 && this.needEndMonitor == 0) {
        this.showCameraPopup = true;
        this.isSubmit = true;
      } else {
        this.handleSubmit()
      }

    },
    handleSubmitCheckNeedMonitor3 () {
      if (this.needMonitor == 0 && this.needEndMonitor == 0) {
        this.showCameraPopup = true;
        this.isSubmit = true;
      } else {
        this.handleSubmit3()
      }

    },
    handleSubmitCheckNeedMonitor2 () {
      this.$confirm(
        `确定要提交吗`,
        '',
        {
          confirmButtonText: '确认',
          cancelButtonText: '继续答题',
          showCancelButton: true,
          showClose: false,
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
        }
      )
        .then(() => {

          if (this.needMonitor == 0 && this.needEndMonitor == 0) {
            this.showCameraPopup = true;
            this.isSubmit = true;
          } else {
            this.$refs.countdown.clearCutDown()
            this.handleSubmit()
          }
        })
        .catch(() => {

        })

    },
    async handleSubmit () {
      await this.handleData()
      submit(this.answerArr).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '成功交卷!',
            onClose: () => {
              if (this.showResultnow) {
                //立即查看结果
                this.getTestResult()
              } else {
                this.$router.go(-1) // 成功回跳上一页
              }

            }
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    async handleSubmit3 () {
      await this.handleData()
      submit(this.answerArr).then((res) => {
        if (res.data.code === 0) {

          this.$confirm(
            `考试时间已结束，您的试卷将自动提交`,
            '温馨提示',
            {
              confirmButtonText: '确认',
              showCancelButton: false,
              // cancelButtonText: '取消',
              type: 'warning',
              showClose: false,
              closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
              closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
            }
          ).then(() => {
            this.$router.go(-1) // 成功回跳上一页
            // location.reload()
            // this.$emit('showResult')
            // this.getTestResult() // 获取考试结果
            // this.showTaskResult = true
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 再次答卷
    async handleReset () {
      console.log('再次答卷....')
      // this.firstStudy(); // 目前还是走第一次考试的模式
      this.showTaskResult = false;
      this.showResultDetail = false;
      this.isSubmit = false;

      //处理单选题
      if (this.singleChoiceQuestionsArr.length > 0) {
        this.singleChoiceQuestionsArr.forEach((item) => {
          Object.assign(item, {
            isChoose: false, // 是否已选择
            isSign: false, // 是否已标记
            optionNo: '', //选项序号
            optionCode: '', //选项编号
            disabled: false
          })
        })
      }
      //处理多选题
      this.multipleChoiceQuestions = [];
      if (this.multipleChoiceQuestionsArr.length > 0) {
        this.multipleChoiceQuestionsArr.forEach((item) => {
          Object.assign(item, {
            isChoose: false, // 是否已选择
            isSign: false, // 是否已标记
            optionNo: '', //选项序号
            optionCode: '', //选项编号
            disabled: false
          })
          this.multipleChoiceQuestions.push([]) // 绑定相应的el-checkbox-group  的v-model
        })
      }
      //处理判断题
      if (this.judgmentQuestionsArr.length > 0) {
        this.judgmentQuestionsArr.forEach((item) => {
          Object.assign(item, {
            isChoose: false, // 是否已选择
            isSign: false, // 是否已标记
            optionNo: '', //选项序号
            optionCode: '', //选项编号
            disabled: false
          })
        })
      }

      //处理简答
      if (this.essayQuestionsArr && this.essayQuestionsArr.length > 0) {
        this.essayQuestionsArr.forEach((item) => {
          Object.assign(item, {
            isChoose: false, // 是否已选择
            isSign: false, // 是否已标记
            optionNo: '', //选项序号
            optionCode: '', //选项编号
            disabled: false,
            answerCode: ''
          })
        })
      }
      location.reload();
      // this.getPaper(true) // 重考
    },

    continueExam () {
      this.isbeforeDestroy = false;
      //继续答题
      const obj = {
        examId: this.examId, //课时id
      }
      //获取提交过的答题数据
      getTaskResultData(obj).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            const testPaperData = res.data.data.paperData
            this.testName = res.data.data.testName

            this.getTestData = true;
            this.singleChoiceQuestionsArr = testPaperData.singleChoiceQuestions
            let codeKey = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8 }
            this.singleChoiceQuestionsArr.forEach((item, index) => {
              if (item.selectedOption && item.selectedOption.length > 0) {
                this.changeInput('singleChoice', index,
                  item.selectedOption,
                  codeKey[item.selectedOption]
                )
                this.singleChoiceQuestions[index] = item.selectedOption
              }
            })

            this.multipleChoiceQuestionsArr = testPaperData.multipleChoiceQuestions
            // 处理多选题数据
            this.multipleChoiceQuestionsArr.forEach((item, index) => {
              console.log(item, index)
              if (item.selectedOption && item.selectedOption.length > 0) {
                this.changeInput('multipleChoice', index,
                  item.selectedOption.split(',')[0],
                  item.selectedOption.split(',')[0]
                )
                this.multipleChoiceQuestions.push(item.selectedOption.split(',')) // 绑定相应的el-checkbox-group  的v-model
              } else {
                this.multipleChoiceQuestions.push([]) // 绑定相应的el-checkbox-group  的v-model
              }
            })
            console.log('多选提结果:', this.answerArr)

            this.judgmentQuestionsArr = testPaperData.judgmentQuestions
            // 处理判断题数据
            codeKey = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8 }
            this.judgmentQuestionsArr.forEach((item, index) => {
              console.log(item, index)
              if (item.selectedOption && item.selectedOption.length > 0) {
                this.changeInput('judgment', index,
                  item.selectedOption,
                  codeKey[item.selectedOption]
                )
                this.judgmentQuestions[index] = item.selectedOption
              }
            })


            this.essayQuestionsArr = res.data.data.paperData.essayQuestions
            // if (res.data.data.paperData.essayQuestions) {
            //   this.essayQuestionsArr.forEach((item, index) => {
            //     if (item.selectedOption && item.selectedOption.length > 0) {
            //       this.changeInput('judgment', index,
            //         item.selectedOption,
            //         codeKey[item.selectedOption]
            //       )
            //       this.essayQuestions[index] = item.selectedOption
            //     }
            //   })

            // }

            this.showTaskResult = false

            // 给各类型的分数、题数
            if (this.prepareData.limitTime && this.prepareData.limitTime > 0) {
              this.$refs.countdown.getServiceTime(res.data.data.examId) // 倒计时开始
            }
            this.singleScore = res.data.data.summary.singleScore
            this.singleCount = res.data.data.summary.singleCount
            this.multipleScore = res.data.data.summary.multipleScore
            this.multipleCount = res.data.data.summary.multipleCount
            this.judgeScore = res.data.data.summary.judgeScore
            this.judgeCount = res.data.data.summary.judgeCount

            //监控
            if (this.needMonitor == 0 && this.needStartMonitor == 0) {

              this.showCameraPopup = true
            }

          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })
    },
    //准备开始考试 -学习进度为未学习
    firstStudy () {
      let _text = '';
      if (this.prepareData.limitTime && this.prepareData.limitTime > 0) {
        _text = `本次考试共${this.prepareData.questionNumber}题，总分${this.prepareData.totalPoint}分，请在${this.prepareData.limitTime}分钟内作答。`;
      } else {
        _text = `本次考试共${this.prepareData.questionNumber}题，总分${this.prepareData.totalPoint}分。`;
      }
      this.$confirm(
        _text,
        '考试说明',
        {
          confirmButtonText: '开始考试',
          showCancelButton: false,
          // cancelButtonText: '取消',
          type: 'warning',
          showClose: false,
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
        }
      )
        .then(() => {
          // resolve()
          this.getTestFun()
        })
        .catch(() => {
        })
    },
    //答案选择
    changeInput (type, parentIndex, optionNo, optionCode) {
      // console.log(type, parentIndex, optionNo, optionCode)
      if (type === 'singleChoice') {
        // 单选题
        this.singleChoiceQuestionsArr.forEach((item, index) => {
          if (parentIndex === index) {
            item.isChoose = true
            this.$set(this.singleChoiceQuestionsArr[index], 'isChoose', true)
            this.$set(
              this.singleChoiceQuestionsArr[index],
              'optionNo',
              optionNo
            )
            this.$set(
              this.singleChoiceQuestionsArr[index],
              'optionCode',
              optionCode
            )

            this.$forceUpdate()
          } else {
            console.log(2)
          }
        })
      }
      if (type === 'judgment') {
        // 判断题
        this.judgmentQuestionsArr.forEach((item, index) => {
          if (parentIndex === index) {
            item.isChoose = true
            console.log('in...')
            this.$set(this.judgmentQuestionsArr[index], 'isChoose', true)
            this.$set(this.judgmentQuestionsArr[index], 'optionNo', optionNo)
            this.$set(
              this.judgmentQuestionsArr[index],
              'optionCode',
              optionCode
            )

            this.$forceUpdate()
          } else {
            console.log('no...')
          }
        })
      }
      // let tmpArr = []
      if (type === 'multipleChoice') {
        // 多选题
        this.multipleChoiceQuestionsArr.forEach((item, index) => {
          if (parentIndex === index) {
            item.isChoose = true
            console.log(this.multipleChoiceQuestions)
            this.$set(this.multipleChoiceQuestionsArr[index], 'isChoose', true)
            this.$set(
              this.multipleChoiceQuestionsArr[index],
              'optionNo',
              optionNo
            )
            // this.$set(this.multipleChoiceQuestionsArr[index], 'optionCode', optionCode)
            if (this.multipleChoiceQuestions[index] && this.multipleChoiceQuestions[index].length == 0) {
              this.$set(this.multipleChoiceQuestionsArr[index], 'isChoose', false)
            }
            this.$forceUpdate()
          } else {
            console.log('no...')
          }
        })
      }
    },

    // //多选题选择
    // changeCheckbox(event) {
    //   console.log(event)
    //   console.log(this.multipleChoiceQuestions)
    // },
    //题目标记
    /* 
    type: 类型
    */
    handleSign (type, parentIndex) {
      console.log(parentIndex)
      if (type === 'singleChoice') {
        //单选题标记点击
        this.singleChoiceQuestionsArr.forEach((item, index) => {
          if (parentIndex === index) {
            this.$set(
              this.singleChoiceQuestionsArr[index],
              'isSign',
              !this.singleChoiceQuestionsArr[index].isSign
            )
            this.$forceUpdate()
          }
        })
      }
      if (type === 'essay') {
        //
        this.essayQuestionsArr.forEach((item, index) => {
          if (parentIndex === index) {
            this.$set(
              this.essayQuestionsArr[index],
              'isSign',
              !this.essayQuestionsArr[index].isSign
            )
            this.$forceUpdate()
          }
        })
      }

      if (type === 'judgment') {
        // 判断题点击
        this.judgmentQuestionsArr.forEach((item, index) => {
          if (parentIndex === index) {
            this.$set(
              this.judgmentQuestionsArr[index],
              'isSign',
              !this.judgmentQuestionsArr[index].isSign
            )
            this.$forceUpdate()
          }
        })
      }
      if (type === 'multipleChoice') {
        // 多选题
        this.multipleChoiceQuestionsArr.forEach((item, index) => {
          if (parentIndex === index) {
            this.$set(
              this.multipleChoiceQuestionsArr[index],
              'isSign',
              !this.multipleChoiceQuestionsArr[index].isSign
            )
            this.$forceUpdate()
          }
        })
      }
    },
    //update 对象属性
    /* updateObjAttribute() {

    } */
    // 获取考试结果
    getTestResult () {
      this.$refs.countdown.clearCutDown();
      const obj = {
        examId: this.examId
      }
      result(obj).then((res) => {
        if (res.data.code === 0) {
          if (Object.keys(res.data.data).length === 0) {
            this.handleSubmitCheckNeedMonitor() // 数据为空先调一次submit接口返回正常数据

            this.$confirm(
              `很遗憾，发生未知错误，您可以再次答卷`,
              {
                confirmButtonText: '再次答卷',
                showCancelButton: false,
                // cancelButtonText: '取消',
                type: 'warning',
                showClose: false,
                closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
                closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
              }
            )
              .then(() => {

                this.handleReset() // 再次答卷
                /* this.$message({
                type: 'success',
                message: '删除成功!',
              }); */
              })
          } else {
            this.isPass = res.data.data.isPass
            this.canRetry = res.data.data.canRetry
            this.getTestData = true;
            this.singleTableData = [
              {
                questionNum:
                  res.data.data.resultSummary.singleSummary.questionNum,
                rightNum: res.data.data.resultSummary.singleSummary.rightNum,
                wrongNum: res.data.data.resultSummary.singleSummary.wrongNum,
                blankNum: res.data.data.resultSummary.singleSummary.blankNum,
                singleScore:
                  res.data.data.resultSummary.singleSummary.singleScore
              }
            ]
            this.multipleTableData = [
              {
                questionNum:
                  res.data.data.resultSummary.multipleSummary.questionNum,
                rightNum: res.data.data.resultSummary.multipleSummary.rightNum,
                wrongNum: res.data.data.resultSummary.multipleSummary.wrongNum,
                blankNum: res.data.data.resultSummary.multipleSummary.blankNum,
                singleScore:
                  res.data.data.resultSummary.multipleSummary.singleScore
              }
            ]
            this.judgmentTableData = [
              {
                questionNum:
                  res.data.data.resultSummary.judgmentSummary.questionNum,
                rightNum: res.data.data.resultSummary.judgmentSummary.rightNum,
                wrongNum: res.data.data.resultSummary.judgmentSummary.wrongNum,
                blankNum: res.data.data.resultSummary.judgmentSummary.blankNum,
                singleScore:
                  res.data.data.resultSummary.judgmentSummary.singleScore
              }
            ]
            if (res.data.data.resultSummary.essaySummary) {
              this.essayTableData = [
                {
                  questionNum:
                    res.data.data.resultSummary.essaySummary.questionNum,
                  rightNum: res.data.data.resultSummary.essaySummary.rightNum,
                  wrongNum: res.data.data.resultSummary.essaySummary.wrongNum,
                  blankNum: res.data.data.resultSummary.essaySummary.blankNum,
                  singleScore:
                    res.data.data.resultSummary.essaySummary.singleScore
                }
              ]
            }

            // 绑定数据
            const { testPaperData } = res.data.data
            this.testName = res.data.data.testName

            // 处理单选题
            this.singleChoiceQuestionsArr = testPaperData.singleChoiceQuestions
            this.singleChoiceQuestionsArr.forEach((item) => {
              Object.assign(item, {
                isChoose: true, // 是否已选择
                // isSign: false, // 是否已标记
                optionNo: 'b', //选项序号
                optionCode: 2, //选项编号
                disabled: true
              })
            })
            console.log(this.singleChoiceQuestionsArr)
            //处理多选题
            this.multipleChoiceQuestionsArr =
              testPaperData.multipleChoiceQuestions
            if (this.multipleChoiceQuestionsArr.length > 0) {
              this.multipleChoiceQuestionsArr.forEach((item) => {
                Object.assign(item, {
                  isChoose: true, // 是否已选择
                  // isSign: false, // 是否已标记
                  optionNo: '', //选项序号
                  optionCode: '', //选项编号
                  disabled: true
                })
                //  this.multipleChoiceQuestions.push([]) // 绑定相应的el-checkbox-group  的v-model
              })
            }

            // 处理判断题
            this.judgmentQuestionsArr = testPaperData.judgmentQuestions
            this.judgmentQuestionsArr.forEach((item,index) => {
              Object.assign(item, {
                isChoose: true, // 是否已选择
                // isSign: false, // 是否已标记
                optionNo: '', //选项序号
                optionCode: item.submitAnswer, //选项编号
                disabled: true,
                selectedOption:item.submitAnswer,
              })
              this.judgmentQuestions[index] = item.submitAnswer
            })

            this.essayQuestionsArr = testPaperData.essayQuestions
            if (testPaperData.essayQuestions) {
              this.essayQuestionsArr.forEach((item) => {
                Object.assign(item, {
                  isChoose: true, // 是否已选择
                  disabled: true,
                  // answerCode:'',
                })
              })
            }


            let codeKey = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8 }
            this.singleChoiceQuestionsArr.forEach((item, index) => {
              if (item.submitAnswer && item.submitAnswer.length > 0) {
                this.changeInput('singleChoice', index,
                  item.selectedOption,
                  codeKey[item.submitAnswer]
                )
                this.singleChoiceQuestions[index] = item.submitAnswer
              }
            })

            this.multipleChoiceQuestionsArr = testPaperData.multipleChoiceQuestions
            // 处理多选题数据
            this.multipleChoiceQuestionsArr.forEach((item, index) => {
              if (item.submitAnswer && item.submitAnswer.length > 0) {
                this.changeInput('multipleChoice', index,
                  item.submitAnswer.split(',')[0],
                  item.submitAnswer.split(',')[0]
                )
                this.multipleChoiceQuestions.push(item.submitAnswer.split(',')) // 绑定相应的el-checkbox-group  的v-model
              } else {
                this.multipleChoiceQuestions.push([]) // 绑定相应的el-checkbox-group  的v-model
              }
            })
            console.log('多选结果:', this.multipleChoiceQuestions)

            this.judgmentQuestionsArr = testPaperData.judgmentQuestions
            // 处理判断题数据
            codeKey = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8 }
            this.judgmentQuestionsArr.forEach((item, index) => {
              if (item.submitAnswer && item.submitAnswer.length > 0) {
                this.changeInput('judgment', index,
                  item.selectedOption,
                  codeKey[item.submitAnswer]
                )
                this.judgmentQuestions[index] = item.submitAnswer=="T"?"A":item.submitAnswer.length>0?"B":''
              }
            })


            // 给各类型的分数、题数
            this.singleScore = res.data.data.summary.singleScore
            this.singleCount = res.data.data.summary.singleCount
            this.multipleScore = res.data.data.summary.multipleScore
            this.multipleCount = res.data.data.summary.multipleCount
            this.judgeScore = res.data.data.summary.judgeScore
            this.judgeCount = res.data.data.summary.judgeCount


            this.userName = res.data.data.userName
            this.submitTime = res.data.data.submitTime
            this.timeCost = formatDuring(res.data.data.timeCost)
            this.score = res.data.data.score
            this.totalScore = res.data.data.totalScore
            this.showTaskResult = true
            this.isPass = res.data.data.isPass
            this.testName = res.data.data.examName
          }
        }
      })
    },
    // 自动提交卷
    async showResult () {
      if( this.isbeforeDestroy){
        return false
      }
      this.isSubmit3 = true;
      await this.handleSubmitCheckNeedMonitor3()
      // await this.getTestResult()
      // setTimeout(() => {
      //   this.showTaskResult = true
      // }, 5000)
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.task {
  background-color: #f5f5f5;
  height: 100%;
  padding: 10px;

  .cont {
    width: 90%;
    margin: 20px auto;

    .card {
      margin-top: 20px;

      .item {
        // float: left;
        width: 100%;
        height: 30px;
        border-bottom: 1px solid #ebebeb;

        .tit {
          float: left;
          position: relative;
          // padding: 16px 0 8px 0;
          font-weight: bold;
          text-indent: 10px;
        }

        .tit::before {
          position: absolute;
          content: '';
          top: 0px;
          left: 0;
          width: 4px;
          height: 16px;
          background: #43bc60;
        }

        .desc {
          float: left;
        }

        .floatR {
          float: right;
          font-size: 12px;
          margin-top: 5px;
          cursor: pointer;
          color: #43bc60;
        }

        /* .flex{
          display: flex;
          .time{
            text-align: left;
            line-height: 32px;
            flex: 1;
          }
          .r-wrap{
            background: #43bc60;
            width: 32px;
            height: 32px;
            text-align: center;
            font-size: 22px;
            line-height: 32px;
            color: #fff;
            cursor: pointer;
          }
        } */
      }

      .border-none {
        border-bottom: none;
      }

      .cont {
        padding: 10px;
      }

      .answer {
        text-align: left;
        max-height: 600px;
        overflow-y: auto;

        .cate-tit {
          font-size: 14px;
        }

        .answer-ul {
          font-size: 14px;
          margin: 10px 0 20px 0;

          li {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            width: 28px;
            height: 28px;
            line-height: 20px;
            text-align: center;
            color: #666;
            padding: 0;
            font-size: 12px;
            line-height: 28px;
            border: 1px solid #e1e1e1;

            i {
              position: absolute;
              top: -2px;
              right: 0px;
              color: #666;
            }
          }

          .click {
            background-color: #46c37b;
            color: #fff;
            border: 1px #46c37b solid;
          }

          .error {
            background-color: #f07371;
            color: #fff;
            border: 1px #f07371 solid;
          }
        }

        .btm-wrap {

          display: flex;
          justify-content: space-around;
        }
      }

      .header {
        // padding-bottom: 20px;
        height: 40px;

        h3 {
          float: left;
          font-size: 24px;
          font-weight: 600;
          color: #333;
          line-height: 32px;
        }

        span {
          display: block;
          float: right;
          color: #46c37b;
          background-color: #eee;
          padding: 4px 8px;
          text-align: center;
          height: 22px;
          font-weight: 500;
          line-height: 22px;
          border-radius: 4px;
          font-size: 12px;
          margin-top: 22px;
        }
      }

      .result-table {
        .result-header {
          display: flex;
          border-bottom: none;

          .left-box {
            flex: 70%;

            .header {
              padding-bottom: 20px;

              h3 {
                float: left;
                font-size: 24px;
                font-weight: 600;
                color: #333;
                line-height: 32px;
              }

              span {
                display: block;
                float: right;
                color: #46c37b;
                background-color: #eee;
                padding: 4px 8px;
                text-align: center;
                height: 22px;
                font-weight: 500;
                line-height: 22px;
                border-radius: 4px;
                margin-right: 20px;
              }
            }

            .content {
              text-align: left;
              color: #666;
            }
          }

          .right-box {
            float: right;

            .cont {
              width: 120px;
              padding-bottom: 8px;
              text-align: center;
              background: #f5f5f5;
              border-radius: 4px;
              margin-top: 0px;

              .defen {
                font-size: 32px;
                font-weight: 600;
                color: #ff8836;
                line-height: 40px;
              }

              .zhongfen {
                color: #333;
                font-weight: 600;
                line-height: 20px;
                font-size: 14px;
              }
            }
          }
        }

        .tit {
          text-align: left;
          margin: 10px 0;
          font-weight: bold;
        }
      }
    }

    .r-item-wrap {
      position: relative;

      .fixed {
        position: fixed;
        z-index: 21;
        top: 0;
      }
    }

    .task-result {
      display: flex;

      .icon-box {
        // width: 30%;

        .img {
          width: 132px;
          height: 80px;
        }
      }

      .content {
        .tit {
          line-height: 32px;
          font-size: 24px;
          font-weight: 500;
          color: #ff8836;
          margin-top: 30px;
        }

        .desc {
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          margin-top: 10px;
          color: #666;
        }
      }
    }

    .result-header {
      display: flex;
    }

    .shiti-item {
      text-align: left;

      .shiti-left {
        position: relative;
        padding-left: 14px;
        padding-top: 2px;

        .timu-no {
          font-size: 14px;
          text-align: center;
        }

        .fen-num {
          margin-top: 5px;
          padding: 4px;
          background-color: #f5f5f5;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          color: #999;
          text-align: center;
        }

        .biaoji {
          color: #46c37b;
          background-color: #fff;
          border: 1px #46c37b solid;
          position: absolute;
          left: -59px;
          top: 10px;
          border-radius: 0 4px 4px 0;
          font-size: 12px;
          padding: 8px 10px;
          cursor: pointer;

          .el-icon-collection-tag {
            padding-right: 2px;
          }

          span {
            font-size: 12px;
          }
        }

        .hoverBiaoji {
          background-color: #46c37b;
          color: #fff;
        }
      }

      .timu-title {
        line-height: 1.5;
      }

      .content {
        margin: 10px 0 20px 0;
        padding-top: 16px;
        padding-bottom: 28px;
        border-bottom: 1px solid #ebebeb;

        ul {
          margin-top: 20px;

          li {
            height: 30px;
            line-height: 30px;
          }

          .reuslt-li {
            color: #333;
            margin-top: 10px;
            font-size: 14px;

            span {
              color: #43bc60;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.retestbtn {
  cursor: pointer;
  color: #46c37b;
  background-color: #eee;
  padding: 0px 8px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  display: inline-block;
  border-radius: 4px;
  margin-right: 20px;
  font-size: 16px;
  position: relative;
  top: -3px;

}

.btm-wrap {
  padding-top: 20px;
  text-align: right;
}
</style>