// 试题相关api
import request from '../utils/request'
// import qs from 'qs'

//0010071-开始考试接口(原生成试题接口）
export function getPaper (params) {
  return request({
    method: 'GET',
    url: 'exam/prepare',
    params
    // data: qs.stringify(data)
  })
}
export function getTest (data) {
  return request({
    method: 'POST',
    url: 'exam/test',
    data
    // data: qs.stringify(data)
  })
}
//0010070-准备开始考试
export function getPrepare (params = {}) {
  return request({
    method: 'GET',
    url: 'exam/prepare',
    params
  })
}

//0010072-获取倒计时
export function getCheckTime (params = {}) {
  return request({
    method: 'GET',
    url: 'exam/checkTime',
    params
  })
}

//0010073-保存考试进度接口
export function save (data) {
  return request({
    method: 'POST',
    url: 'exam/save',
    data
    // data: qs.stringify(data)
  })
}

//0010074-提交考试答案接口
export function submit (data) {
  return request({
    method: 'POST',
    url: 'exam/submit',
    data
    // data: qs.stringify(data)
  })
}

//0010075-重新开始考试接口
export function reTest (data) {
  return request({
    method: 'POST',
    url: 'exam/reTest',
    data
    // data: qs.stringify(data)
  })
}

//0010076-查询最近一次考试是否结束
export function checkLastTest (params = {}) {
  return request({
    method: 'GET',
    url: 'exam/checkLastTest',
    params
  })
}

//0010077-获取考试结果
export function result (data) {
  return request({
    method: 'POST',
    url: 'exam/result',
    data
    // data: qs.stringify(data)
  })
}

//0010078-获取未完成考试信息
export function getTaskResultData (params = {}) {
  return request({
    method: 'GET',
    url: 'exam/data',
    params
  })
}