<template>
  <div class="flex">
    <p class="time">倒计时：{{ showText }}</p>
    <div class="r-wrap">
      <i class="el-icon-video-pause"></i>  
    </div>
  </div>
</template>

<script>
import { getCheckTime } from "@/api/task";
export default {
  name: "Countdown",
  props: {
    intervalTime: Number,
    needMonitor:Number
  },
  data() {
    return {
      serviceTime: "", // 服务器考试开始时间
      duration: 0, // 考试时长
      showText: "",
      isValid: false , // 设置是否为进行中
    };
  },
  watch: {
    isValid: function(val, oldVal) {
      console.log(val, oldVal);
      if(val) { //考试当中执行
        console.log('开始')
        this.init()
      }
    }
  },
  mounted() {
    // this.countDown()
  },
  beforeDestroy(){
    console.log('beforeDestroybeforeDestroybeforeDestroy')
    this.clearCutDown();
  },
  methods: {
    init() {
      if(this.isValid) {
        // 考试进行中每隔5秒 触发上报提交数据； 重新获取考试剩余时长
        setInterval(()=>{
          this.$emit('autoSave')
         // this.getServiceTime(this.examId)
        }, 1000* 60 * 5) // 5分钟自动保存一次答卷 
      }
    },
    //倒计时：先获取时间间隔的秒数 之后把相差的秒数转为即时的时分秒
    countDown() {
      if(!this.isValid){
        return false;
      }
      //开始的时间
      var start = new Date(this.serviceTime.replace(/-/g, "/")).getTime();
      //表示之前的时间加考试时长分钟
      var endTime = start + this.duration * 60000;
      var date = new Date(); //现在时间
      var now = date.getTime(); //现在时间戳
      //先获取时间间隔的秒数 之后把相差的秒数转为即时的时分秒
      var allTime = endTime - now;
      var passTime = now-start+10000;
      var h, m, s, hText, mText, sText;
      if (allTime > 0) {
        h = Math.floor((allTime / 1000 / 60 / 60) % 60);
        hText = h < 10 ? `0${h}` : `${h}`;
        m = Math.floor((allTime / 1000 / 60) % 60);
        mText = m < 10 ? `0${m}` : `${m}`;
        s = Math.floor((allTime / 1000) % 60);
        sText = s < 10 ? `0${s}` : `${s}`;
        setTimeout(() => {
          this.showText = `${hText}：${mText}：${sText}`;
          this.countDown();
          if(this.intervalTime!=0&&this.needMonitor==0){
            if(parseInt((passTime/1000)%(this.intervalTime*60))==0){
              this.$emit('intervalTimeMonitorFun')
            }
          }
        }, 1000);
        
      } else {
        //倒计时已到
        console.log("已截止");
        this.showText = "00:00:00";
        this.isValid = false 
        //考试结束提示
        this.$emit('showResult')
        // this.$confirm(
        //   `考试时间已结束，您的试卷将自动提交`,
        //   '温馨提示',
        //   {
        //     confirmButtonText: '确认',
        //     showCancelButton: false,
        //     // cancelButtonText: '取消',
        //     type: 'warning',
        //     showClose: false,
        //     closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
        //     closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
        //   }
        // ).then(() => {
        //     // location.reload()
        //     // this.$emit('showResult')
        //     // this.getTestResult() // 获取考试结果
        //     // this.showTaskResult = true
        // })
      }
    },
    clearCutDown(){
      this.isValid = false;
    },
    // 获取服务器时间
    getServiceTime(examId) {
      this.examId = examId // 获取考试id
      const obj = {
        examId: examId,
      };
      return new Promise((resolve, reject) => {
        getCheckTime(obj).then((res) => {
          if (res.data.code === 0) {
            this.serviceTime = res.data.data.startTime;
            this.duration = res.data.data.duration;
            resolve();
            this.begin();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            reject(res);
          }
        });
      });
    },
    begin() {
      // this.countDown();
      this.isValid = true // 设置为进行中
      this.countDown();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.flex {
  display: flex;
  .time {
    text-align: left;
    line-height: 32px;
    flex: 1;
  }
  .r-wrap {
    background: #43bc60;
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    color: #fff;
    cursor: pointer;
  }
}
</style>