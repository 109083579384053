/*
 *秒转化成 时分秒
 * @param {number} second
 * @returns {string}
 */
export function secondToDate(second) {
  var h = Math.floor(second / 3600);
  h = h < 10 ? "0" + h : h;
  var m = Math.floor((second / 60) % 60);
  m = m < 10 ? "0" + m : m;
  var s = Math.floor(second % 60);
  s = s < 10 ? "0" + s : s;
  return (second = h + ":" + m + ":" + s);
}

/* 
  将毫秒转化成天时分秒的时间格式
*/
export function formatDuring(mss) {
  // var days = parseInt(mss / (1000 * 60 * 60 * 24));
  var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = parseInt((mss % (1000 * 60)) / 1000);
  if(hours>0){
    return (
      hours + "小时" + minutes + "分钟" + seconds + "秒"
    );
  }
  if(minutes>0){
    return (
     minutes + "分钟" + seconds + "秒"
    );
  }
  return (
    seconds + "秒"
  );
}
